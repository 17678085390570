
import Vue from 'vue'
export default Vue.extend({
    data() {
        return {
            products: [
                {
                    title: 'Personal Website',
                    icon: 'mdi-vuejs',
                    button: {
                        title: 'Source Control',
                        href: 'https://github.com/nelsondev/nelsondev.github.io'
                    },
                    list: [
                        "I've done personal, shop, professional and industry websites in both freelance and office environments. Both in <strong>Vue.js</strong>, and <strong>React.js</strong>.",
                        '<b>Technologies:</b>',
                        '&#8226;&emsp;Vue.js / Vuetify',
                        '&#8226;&emsp;Typescript',
                    ],
                    attrs: {
                        flat: true,
                        dark: false,
                        outlined: true,
                        style: { 'border': '1px solid #37474F' },
                        class: 'blue-grey--text',
                        secondaryColor: 'blue-grey'
                    }
                },
                {
                    title: 'Platform Website',
                    icon: 'mdi-code-braces-box',
                    headliner: 'My favorite',
                    button: {
                        title: 'See more',
                        href: 'https://www.peloton.com/peloton-platform-overview/'
                    },
                    list: [
                        'User management and application distribution at a large scale. Backend, frontend and full <abbr title="OAuth 2.0 Authentication backend.">IDP</abbr> migration from Identity Server 4.0.',
                        '<b>Technologies</b>',
                        '&#8226;&emsp;Auth0',
                        '&#8226;&emsp;C# .NET 5.0',
                        '&#8226;&emsp;Vue.js / Vuetify',
                    ],
                    attrs: {
                        elevation: 8,
                        dark: true,
                        color: 'blue-grey darken-3',
                        class: 'white--text',
                        secondaryColor: 'white'
                    }
                },
                {
                    title: 'Game Engine',
                    icon: 'mdi-gamepad-circle',
                    button: {
                        title: 'Source Control',
                        href: 'https://github.com/nelsondev/line-engine'
                    },
                    list: [
                        "Created as just a fun and challenging at home project named <strong>\"ASCII Engine.\"</strong> It's created as a simple and easy to use game engine with built in controls, multi-threading operation, and \"Sprite\" creation via the editor.",
                        '<b>Technologies</b>',
                        '&#8226;&emsp;C# .NET 5.0',
                        '&#8226;&emsp;WPF'
                    ],
                    attrs: {
                        flat: true,
                        dark: false,
                        outlined: true,
                        style: { 'border': '1px solid #37474F' },
                        class: 'blue-grey--text',
                        secondaryColor: 'blue-grey'
                    }
                }
            ]
        }
    }
})
